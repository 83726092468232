import  { FC, useEffect, useState } from "react"
import { Box, Typography, Modal, Button, Grid, TextField } from '@mui/material';
import { modalStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { uploadCSVFile } from "@/store/actions";
import { toBase64, uploadValues } from "@/utils";

const UploadModal: FC<any> = ({ open, handleClose }) => {

  const [errors, setErrors] = useState<any>({})
  const { intermitence: { error }, company: { company } } = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const checkInput = (event: any) => {
    const value = event?.currentTarget?.value
    const name = event?.currentTarget?.name
    const selection: any = {
      'name': null,
      'contactFirstName': null,
      'contactLastName': null,
      'contactEmail': null
    }
    const check = (selection[name]) ? !selection[name]?.test(value) : false
    if (check) return setErrors((old: any) => ({ ...old, [name]: true }))
    return setErrors((old: any) => ({ ...old, [name]: false }))
  }

  const handleSubmit = (event: any) => {
    event?.preventDefault();
    const form = new FormData(event?.target)
    const values = form.entries();
    const formValues: any = {}
    for (const val of values) formValues[val[0]] = val[1]
    const reader = new FileReader()
    reader.readAsText(formValues.file)
    reader.onload = async (e) => {
      const result: string = e?.target?.result as string
      const rows = result?.replaceAll('\r', '')?.split('\n')
      const headers = rows[0].split(',')
      const checkHeaders = uploadValues.reduce((prev: any, next: any) => {
        prev = prev && headers.includes(next)
        return prev
      }, true)

      if (!checkHeaders) setErrors((old: any) => ({ ...old, file: true }))
      else {
        const base64File = await toBase64(formValues.file)
        dispatch(uploadCSVFile({ file: base64File }))
        handleClose()
      }
    }
  };

  useEffect(() => {
    if (Object.keys(company || {})?.length) window.location.reload()
  }, [company])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyles}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Upload CSV
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                required
                fullWidth
                id="name"
                label="Company Name"
                name="file"
                type="file"
                autoComplete="file"
                error={errors?.file}
                onChange={checkInput}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!(Object.keys(errors)?.length >= 1) || errors?.file}
          >
            Create
          </Button>
          {!!error && <Typography component="p" color={'error'}>
            {error || ''}
          </Typography>}
        </Box>
      </Box>
    </Modal>
  )
}

export default UploadModal
