const header = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'COMPANY NAME',
  },
  {
    id: 'contactFirstName',
    numeric: false,
    disablePadding: true,
    label: 'CONTACT FIRST NAME',
  },
  {
    id: 'contactLastName',
    numeric: false,
    disablePadding: true,
    label: 'CONTACT LAST NAME',
  },
  {
    id: 'contactEmail',
    numeric: false,
    disablePadding: false,
    label: 'CONTACT EMAIL',
  },
  {
    id: 'active',
    numeric: false,
    disablePadding: false,
    label: 'ACTIVE',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'ACTIONS',
  },
]

export default header
